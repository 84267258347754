function Home() {
  return (
    <div className="min-h-screen h-full select-none bg-zinc-900 text-white">
      <div className="sm:flex-row flex flex-col h-screen">
        <div className="relative flex sm:h-full xl:max-w-2xl lg:max-w-md sm:max-w-sm w-full h-64 shrink-0 flex-col justify-center">
          <div className="absolute left-0 top-0 h-full w-full overflow-hidden">
            <img
              src="/bg.webp"
              alt=""
              className="h-full w-full object-cover blur-sm"
            />
            <div className="absolute left-0 top-0 h-full w-full sm:bg-gradient-to-r bg-gradient-to-t from-zinc-900/60 to-zinc-900" />
          </div>
          <svg
            className="z-10 mx-auto w-40 sm:-translate-y-1/2 -translate-y-1/4 fill-white"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 300"
          >
            <path
              fillRule="evenodd"
              d="M145.834 125.783c.23.559 13.269 34.137 28.976 74.617 15.706 40.48 28.738 74.05 28.959 74.6.307.762 5.121 1 20.259 1h19.857l1.585-4.2c1.534-4.064 55.856-143.964 56.697-146.017.331-.807-2.944-.972-15.865-.8l-16.282.217-22.81 58.982c-12.546 32.44-22.99 58.985-23.21 58.99-.22.004-10.669-26.538-23.221-58.982l-22.82-58.99-16.272-.217c-12.912-.172-16.185-.007-15.853.8M96.8 199.986V274.4h49.267c29.187 0 49.158-.296 49-.727-.147-.399-2.784-7.239-5.861-15.2L183.612 244H126.4v-28.8h22.8c12.54 0 22.8-.298 22.8-.662 0-.364-.85-2.794-1.89-5.4-1.039-2.606-3.59-9.148-5.667-14.538l-3.778-9.8H126.4v-29.6h11.2c6.16 0 11.195-.27 11.188-.6-.006-.33-2.464-6.9-5.461-14.6l-5.449-14-20.539-.214-20.539-.213v74.413m89.6-74.141c0 .135 1.468 4.005 3.262 8.6A6053.81 6053.81 0 0 1 195.32 149l2.396 6.2h52.568l2.396-6.2c1.318-3.41 3.865-9.96 5.658-14.555 1.794-4.595 3.262-8.465 3.262-8.6 0-.135-16.92-.245-37.6-.245s-37.6.11-37.6.245m96.837 68.059a61985.698 61985.698 0 0 1-25.718 66.296l-6.155 15.8H283.711l9.752-25.203 9.751-25.204-.207-41.093-.207-41.093-19.563 50.497m-73.635-8.504c.001.33 2.521 7.06 5.6 14.955 3.079 7.895 5.598 14.465 5.598 14.6 0 .135 1.384.245 3.076.245h3.075l5.725-14.646c3.148-8.056 5.724-14.896 5.724-15.2 0-.305-6.48-.554-14.4-.554s-14.399.27-14.398.6m87.852 77-5.266 13.6H303.2v-13.6c0-7.48-.108-13.6-.239-13.6-.132 0-2.61 6.12-5.507 13.6"
            />
          </svg>
        </div>
        <div className="sm:mr-24 flex-1 my-auto h-fit flex-grow justify-center gap-4 p-14 bg-zinc-900 flex-shrink-0 overflow-y-auto">
          <div className="text-2xl font-medium">Community Create V</div>
          <div className="text-sm">
            Liebe Create-Fans,
            <br />
            <br />
            <b>
              unser Create Projekt "Community Create" geht in die nächste Runde!
            </b>
            <br />
            Wir haben uns erneut daran gesetzt, ein umfangreiches, ausgewogenes
            und kreatives Modpack zu erstellen, in dem die <b>Create-Mod</b> im
            Vordergrund steht! Neben den aus den <b>Create Life</b> bekannten
            Mods, haben wir noch einiges hinzugefügt. Auch einige neue{" "}
            <b>Rezepte</b> haben wir erstellt.
            <br />
            <br />- <b>Züge, Fabriken, Essen, Backpacks und vieles mehr</b>{" "}
            bringt unser Modpack mit.
            <br />- Egal ob <b>Anfänger</b> oder <b>Modpack-Veteran</b>, wir
            freuen uns auf jeden, der neu dazu kommt oder schon auf unser
            nächstes Projekt gewartet hat!
            <br />- Ein (öffentlicher) Server auf dem jeder mitspielen kann mit
            einwandfreier Performance.
            <br />- Projektstart ist der <b>29.03.2024</b>!<br />
            <br />
            <b>
              <u>Wie kann ich mitspielen?</u>
            </b>
            <br />
            Ganz einfach! Tritt unserem Discord bei und benutze in irgendeinem
            Channel den Command "/register{" <dein Minecraft Name>"}" und schon
            bist du dabei!
            <br />
            <br />
            Wir freuen uns auf euch,
            <br />
            das Evolve Team.
          </div>
          <div className="mt-6 flex gap-2">
            <a
              href="https://discord.gg/vmBTX9umhT"
              className="rounded-sm border border-white px-5 py-2 text-sm duration-200 hover:bg-white/10 focus:bg-white/20"
            >
              Discord
            </a>
            <a
              href="https://curseforge.com/minecraft/modpacks/community-create-v"
              className="rounded-sm border border-white px-5 py-2 text-sm duration-200 hover:bg-white/10 focus:bg-white/20"
            >
              CurseForge
            </a>
          </div>
          <div className="sm:h-1/5 h-0" />
        </div>
      </div>
    </div>
  );
}

export default Home;
