import { useEffect } from "react";

function Redirect({ url = "/" }) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return (
    <div className="min-h-screen bg-black text-white flex items-center justify-center">
      <div className="flex flex-col gap-2 justify-center items-center">
        You are being redirected...
        <a href={url} className="text-neutral-400 text-sm underline">
          Click here if you are not redirected
        </a>
      </div>
    </div>
  );
}

export default Redirect;
