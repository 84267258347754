import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Brand from "./pages/Brand";
import Home from "./pages/Home";
import Redirect from "./pages/Redirect";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/discord"
          element={<Redirect url="https://discord.gg/vmBTX9umhT" />}
        />
        <Route
          path="/curseforge"
          element={
            <Redirect url="https://curseforge.com/minecraft/modpacks/community-create-v" />
          }
        />
        <Route path="/brand" element={<Brand />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
